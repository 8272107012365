export default {
  computed: {

    arrondissements() {
      return this.$store.state.mainAppStore.arrondissements
    },

  },
  created() {
    this.$store.dispatch('mainAppStore/fetchArrondissementsListItems').then(response => {
      console.log(response.data)
    })
      .catch(err => {
        console.error(err.response.headers)
      })
  },
}
